import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import exhibitorsLogo from "../assets/svg/ss/exhibition.svg";
import notFoundImage from "../assets/svg/ss/page-not-found.svg";
import profileLogo from "../assets/svg/ss/profile.svg";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import EditImage from "@material-ui/icons/Edit";
import EnhancedTableToolbar from "./enhancedToolbar";
import axios from "axios";
import config from "../config.json";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Image, { Shimmer } from "react-shimmer";
import { useHistory } from "react-router-dom";
import lstrings from "../lstrings";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function createData(slno, data) {
    return { slno, data };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const dir = document.getElementsByTagName("html")[0].getAttribute("dir");
    const setDir = dir === "rtl" ? true : false;

    const headCells = [
        { id: "slno", numeric: true, disablePadding: true, label: "SL" },
        { id: "name", numeric: false, disablePadding: false, label: "User Name" },
        { id: "email", numeric: false, disablePadding: false, label: "Coordinates" },
        { id: "roles", numeric: false, disablePadding: false, label: "Roles" },
        { id: "action", numeric: false, disablePadding: false, label: "Actions" },
    ];
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={!setDir ? "left" : "right"} padding={headCell.disablePadding ? "none" : "default"} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function MeterDataValidateNow(props) {
    const dir = document.getElementsByTagName("html")[0].getAttribute("dir");

    const useStyles = makeStyles((theme) => ({
        root: {
            width: "calc(100%)",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
            paddingLeft: 20,
            paddingRight: 20,
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        grid: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
            marginBottom: "10px",
        },
        total: {
            textAlign: "left",
        },
        totalDetails: {
            marginRight: "20px",
        },
        totalAttendes: {
            display: "flex",
            alignItems: "baseline",
            width: "30%",
            // marginRight: '80px'
            // borderRight: "1px solid #CACACA",
            "@media (max-width: 600px)": {
                width: "300px",
            },
        },
        progress: {
            width: "70%",
            "@media (max-width: 600px)": {
                width: "600px",
            },
        },
        h1: {
            margin: "0px",
            paddingRight: "10px",
            paddingLeft: "10px",
        },
        vl: {
            border: "1px solid #CACACA",
            height: " 40px",
        },
        search: {
            position: "relative",
        },
        searchIcon: {
            height: "100%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
        },
        inputInput: {
            // width: '90%',
            paddingLeft: "30px",
            paddingRight: "30px",
            borderBottom: "1px solid #CACACA",
        },
        inputRoot: {
            width: "100%",
        },
        button: {
            background: "white",
            textTransform: "capitalize",
        },
        exhibitor_image: {
            marginRight: "10px",
        },
        flex: {
            display: "flex",
            alignItems: "center",
        },
        dot: {
            height: "10px",
            width: "10px",
            backgroundColor: "#bbb",
            borderRadius: "50%",
            display: "inline-block",
            marginRight: "7px",
            marginLeft: "7px",
        },
        dotActive: {
            height: "10px",
            width: "10px",
            backgroundColor: "#4287F5",
            borderRadius: "50%",
            display: "inline-block",
            marginRight: "7px",
            marginLeft: "7px",
        },
        addButton: {
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            width: "70%",
            // marginRight: '80px'
            // borderRight: "1px solid #CACACA",
            "@media (max-width: 600px)": {
                width: "300px",
            },
        },
    }));

    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(true);
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [rows, setRows] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalVisited, setTotalVisited] = React.useState(0);
    const history = useHistory();

    const pageLimits = [10, 25, 50];
    let offset = 0;

    async function getList(numberOfRows) {
        try {
            let url = config["baseurl"] + "/api/user/list?role=meter-data-validator";
            axios.defaults.headers.common["authToken"] = window.localStorage.getItem("authToken");
            const { data } = await axios.get(url);
            console.log(data);
            console.log(data.count);
            setTotalCount(data.count);
            console.log(data.users);
            let newRows = [];
            for (let i = 0; i < data.count; ++i) {
                console.log("getList: 1");
                newRows.push(createData(offset + i + 1, data.users[i]));
                console.log("getList: 2");
            }

            setRows(newRows);
        } catch (e) {
            console.log("Error in getting users list");
            setErrorMessage("Error in getting users list");
            setShowError(true);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShowError(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        offset = newPage * rowsPerPage;
        setPage(newPage);
        getList(rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        offset = 0;
        getList(newRowsPerPage);
    };

    const handleEdit = (userdata) => {
        console.log("handleEdit: ", userdata);

        // props.setSelectedUser(userdata);
        // props.history.push("/meter-data-validate-now");
    };

    const handleGetNew = () => {
        props.history.push("/get-new-meter-data-for-validation");
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: "#1a90ff",
        },
    }))(LinearProgress);

    const getStringForArray = (data) => {
        let val = "";
        for (let i = 0; i < data.length; ++i) {
            if (i > 0) val += ", ";
            val += data[i];
        }
        return val;
    };

    async function getValidatedByMe() {
        try {
            let url = config["baseurl"] + "/api/meterreplacementdata/countvalidatedbyme";
            axios.defaults.headers.common["authToken"] = window.localStorage.getItem("authToken");
            const { data } = await axios.get(url);
            console.log(data);
            setTotalCount(data.count);
        } catch (e) {}
    }

    useEffect(() => {
        getValidatedByMe();
    }, []);

    return (
        <div className={clsx(classes.root)}>
            {props.refreshUI && (
                <div className={classes.paper}>
                    <EnhancedTableToolbar title={"Validate Meter Data"} />
                    <Paper className={classes.grid}>
                        <Grid container spacing={2}>
                            <Grid item className={classes.totalAttendes}>
                                {/* <img src={profileLogo} width='25' alt="" /> */}
                                <h1 className={classes.h1}>{totalCount}</h1>
                                <span>{"Meter data validated [Today]"}</span>
                            </Grid>
                            <Grid item className={classes.addButton}>
                                <Button onClick={() => handleGetNew()} style={{ background: "#314293", color: "#FFFFFF" }} variant="contained" className={classes.button}>
                                    {"Get New"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            )}
            <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}
