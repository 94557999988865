import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import EnhancedTableToolbar from './enhancedToolbar';
import axios from 'axios';
import config from "../config.json";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/date-fns';
import ProcurementImage from '../assets/svg/ss/commercial-2.svg';
import IconButton from '@material-ui/core/IconButton';
import EditImage from '@material-ui/icons/Edit';
import GetAppImage from '@material-ui/icons/GetApp';
import DetailImage from '@material-ui/icons/ArrowForward';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function createData(slno, data) {
  return { slno, data };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const dir = document.getElementsByTagName('html')[0].getAttribute('dir');
  const setDir = (dir === 'rtl' ? true : false);

  const headCells = [
    { id: 'slno', numeric: true, disablePadding: true, label: 'SL' },

    { id: 'Division', numeric: false, disablePadding: false, label: 'Division' },
    { id: 'SubDivision', numeric: false, disablePadding: false, label: 'SubDivision' },
    { id: 'Section', numeric: false, disablePadding: false, label: 'Section' },
    { id: 'Activity', numeric: false, disablePadding: false, label: 'Activity' },

    { id: 'Bill Number', numeric: false, disablePadding: false, label: 'RR Number' },
    { id: 'AccountID', numeric: false, disablePadding: false, label: 'Account ID' },
    { id: 'Bill Image', numeric: false, disablePadding: false, label: 'Bill Image' },

    { id: 'Old Meter Number', numeric: false, disablePadding: false, label: 'Old Meter Serial Number' },
    { id: 'Final Reading', numeric: false, disablePadding: false, label: 'Final Reading' },
    { id: 'Old Meter Make', numeric: false, disablePadding: false, label: 'Old Meter Make' },
    { id: 'Capacity Old Meter', numeric: false, disablePadding: false, label: 'Capacity Old Meter' },
    { id: 'Old Meter Image', numeric: false, disablePadding: false, label: 'Old Meter Image' },

    { id: 'New Meter Number', numeric: false, disablePadding: false, label: 'New Meter Serial Number' },
    { id: 'Initial Reading', numeric: false, disablePadding: false, label: 'Initial Reading' },
    { id: 'New Meter Make', numeric: false, disablePadding: false, label: 'New Meter Make' },
    { id: 'Capacity New Meter', numeric: false, disablePadding: false, label: 'Capacity New Meter' },
    { id: 'New Meter Image', numeric: false, disablePadding: false, label: 'New Meter Image' },

    { id: 'DataCollector Name', numeric: false, disablePadding: false, label: 'DataCollector Name' },
    { id: 'DataCollector Phone', numeric: false, disablePadding: false, label: 'DataCollector Phone' },

    { id: 'Lat,Long', numeric: false, disablePadding: false, label: 'Lat,Long' },

    { id: 'DataValidator Name', numeric: false, disablePadding: false, label: 'DataValidator Name' },

    { id: 'createddate', numeric: false, disablePadding: false, label: 'Created Date' }
  ];

  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={!setDir ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function MeterDataMyReports(props) {

  // console.log("props: " + props);

  const dir = document.getElementsByTagName('html')[0].getAttribute('dir');

  const useStyles = makeStyles((theme) => ({
    root: {
      width: 'calc(100%)',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      paddingLeft: 20,
      paddingRight: 20,
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    grid: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginBottom: '10px',
    },
    total: {
      textAlign: "left",
    },
    totalDetails: {
      marginRight: '20px',
    },
    totalAttendes: {
      display: 'flex',
      alignItems: 'baseline',
      width: '100%',
      // marginRight: '80px'
      // borderRight: "1px solid #CACACA",
      '@media (max-width: 600px)': {
        width: "300px",
      }
    },
    progress: {
      width: '70%',
      '@media (max-width: 600px)': {
        width: "600px",
      }
    },
    h1: {
      margin: '0px',
      paddingRight: '10px',
      paddingLeft: '10px'
    },
    vl: {
      border: '1px solid #CACACA',
      height: ' 40px',
    },
    search: {
      position: 'relative',
    },
    searchIcon: {
      height: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
    },
    inputInput: {
      // width: '90%',
      paddingLeft: '30px',
      paddingRight: '30px',
      borderBottom: '1px solid #CACACA'

    },
    inputRoot: {
      width: '100%',
    },
    button: {
      background: 'white',
      textTransform: 'capitalize'
    },
    exhibitor_image: {
      marginRight: '10px'
    },
    flex: {
      display: 'flex',
      alignItems: 'center'
    },
    dot: {
      height: '10px',
      width: '10px',
      backgroundColor: '#bbb',
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '7px',
      marginLeft: '7px',
    },
    dotActive: {
      height: '10px',
      width: '10px',
      backgroundColor: '#4287F5',
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '7px',
      marginLeft: '7px',
    },
    addButton: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'flex-end',
      width: '70%',
      // marginRight: '80px'
      // borderRight: "1px solid #CACACA",
      '@media (max-width: 600px)': {
        width: "300px",
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

  }));

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [supplyVendors, setSupplyVendors] = React.useState([]);
  const [allItems, set_allItems] = React.useState([]);
  const [uoms, set_uoms] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [warehouses, setWarehouses] = React.useState([]);

  const [filters, set_filters] = React.useState([]);

  const [value, setValue] = React.useState(new Date());
  const [showBackDrop, setShowBackDrop] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState("");

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");

  const [divisions, setDivisions] = React.useState([]);
  const [subDivisions, setSubDivisions] = React.useState([]);
  const [sections, setSections] = React.useState([]);
  const [activities, setActivities] = React.useState([]);
  const [serviceVendors, setServiceVendors] = React.useState([]);

  const [currentSection, setCurrentSection] = React.useState(null);
  const [currentDataCollector, setCurrentDataCollector] = React.useState(null);
  
  const [dataCollectors, setDataCollectors] = React.useState([]);

  const pageLimits = [10, 25, 50];
  let offset = 0;

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  async function getSkippedMeterData(numberOfRows, search = "") {
    try {
      setShowBackDrop(true);
      console.log("page: ", page);
      let skipped = 0;
      let validated = 0;
      let notvalidated = 0;
      for (let i = 0; i < filters.length; ++i) {
        if (filters[i] == "skipped")
          skipped = 1;
        else if (filters[i] == "validated")
          validated = 1;
        else if (filters[i] == "notvalidated")
          notvalidated = 1;
      }

      let url = config["baseurl"] + "/api/meterreplacementdata/myverifiedlist?count=" + numberOfRows + "&offset=" + offset + "&validated=1" + "&startdate=" + startDate + "&enddate=" + endDate + "&section=" +(currentSection?currentSection._id:"")+"&datacollector=" +(currentDataCollector?currentDataCollector._id:"");
      axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
      const { data } = await axios.get(url);
      console.log(data);
      let newRows = [];
      setTotalCount(data.totalDocs);
      const dateFns = new DateFnsUtils();
      for (let i = 0; i < data.list.length; ++i) {
        // let row = data.list[i];
        // data.list[i].createddate_conv = dateFns.date(row.transaction.createdDate);
        // row.transaction.bill_date_conv = dateFns.date(data.list[i].transaction.bill_date);
        data.list[i].data.createdDate_conv = dateFns.date(data.list[i].data.createdDate);
        newRows.push(createData((offset + i + 1),
          data.list[i]
        ));
      }

      setRows(newRows);
      setShowBackDrop(false);
    }
    catch (e) {

      if (e.response) {
        setErrorMessage(e.response.data.message);
      }
      else {
        setErrorMessage("Error in getting list");
      }
      setShowError(true);
      setShowBackDrop(false);
    }
  }

  async function getDataCollectors() {
    if(currentSection == null)
      return;

    setShowBackDrop(true);
    try {
      let url = config["baseurl"] + "/api/datacollectuser/list-by-section?section="+currentSection._id;
      axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
      const { data } = await axios.get(url);
      console.log(data.list);
      setDataCollectors(data.list);
    }
    catch (e) {
    }

    setShowBackDrop(false);
  }

  useEffect(() => {
    getDataCollectors ();
    getDivisionList();
    getSubDivisionList();
    getSectionList();
    getActivityList();    
    getSkippedMeterData(rowsPerPage);
  }, []);

  useEffect(() => {
    getDataCollectors();
  }, [currentSection]);

  const getDivisionById = (id) => {
    for(let i=0; i<divisions.length; ++i){
      if(divisions[i]._id == id){
        return divisions[i].name;
      }
    }
    return id;
  }

  const getSubDivisionById = (id) => {
    for(let i=0; i<subDivisions.length; ++i){
      if(subDivisions[i]._id == id){
        return subDivisions[i].name;
      }
    }
    return id;
  }

  const getSectionById = (id) => {
    for(let i=0; i<sections.length; ++i){
      if(sections[i]._id == id){
        return sections[i].name;
      }
    }
    return id;
  }

  const getActivityById = (id) => {
    for(let i=0; i<activities.length; ++i){
      if(activities[i]._id == id){
        return activities[i].name;
      }
    }
    return id;
  }

  // useEffect(() => {
  //   getSkippedMeterData(rowsPerPage);

  // }, [filters]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowError(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    offset = newPage * rowsPerPage;
    setPage(newPage);
    getSkippedMeterData(rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    offset = 0;
    getSkippedMeterData(newRowsPerPage);
  };

  const handleAdd = () => {
    console.log("calling goto");
    props.goto("receivematerial");
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },

  }))(LinearProgress);

  const getStringForArray = (data) => {
    let val = "";
    for (let i = 0; i < data.length; ++i) {
      if (i > 0)
        val += ", ";
      val += data[i];
    }
    return val;
  }

  const onSearchChange = (event) => {
    console.log(event.target.value);

    getSkippedMeterData(rowsPerPage, event.target.value);
  };

  const handleCloseBackDrop = () => {

  };

  const getSupplyVendorName = (id) => {
    for (let i = 0; i < supplyVendors.length; ++i) {
      if (supplyVendors[i]._id === id)
        return supplyVendors[i].name;
    }
    return id;
  };

  const getSupplyVendor = (id) => {
    for (let i = 0; i < supplyVendors.length; ++i) {
      if (supplyVendors[i]._id === id)
        return supplyVendors[i];
    }
    return null;
  };

  const editAction = (data) => {
    console.log(data);
    props.setPO(data);
    props.goto("editPO", data);
  };

  const getItem = (id) => {
    for (let i = 0; i < allItems.length; ++i) {
      if (allItems[i]._id === id)
        return allItems[i];
    }
    return null;
  };

  const getBase64ImageFromURL = (url) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  };

  const getUOMName = (id) => {
    for (let i = 0; i < uoms.length; ++i) {
      if (uoms[i]._id === id) {
        return uoms[i].name;
      }
    }

    return id;
  };

  const getProjectName = (id) => {
    for (let i = 0; i < projects.length; ++i) {
      if (projects[i]._id === id)
        return projects[i].name;
    }
  };

  const getWarehouseName = (id) => {
    for (let i = 0; i < warehouses.length; ++i) {
      if (warehouses[i]._id === id)
        return warehouses[i].name;
    }
  };

  const getWarehouseAddress = (id) => {
    for (let i = 0; i < warehouses.length; ++i) {
      if (warehouses[i]._id === id)
        return warehouses[i].address;
    }
  };

  const detailAction = (data) => {
    props.set_skipped_meter_data_id(data.data._id);
    props.history.push("/meter-data-skipped-details");
  };

  const AddAction = () => {
    // if (num.length > 0) {
    //   let serials = [...serial_nums, num];
    //   set_serial_nums(serials);
    // }
  }

  const getDivisionList = async () => {
    try {
        let url = config["baseurl"] + "/api/division/list?count=" + 10000 + "&offset=" + 0 + "&search=" + "";
        axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
        const { data } = await axios.get(url);

        setDivisions(data.list.docs);
    }
    catch (e) {
        console.log(e.response);
        if (e.response) {
            setErrorMessage(e.response.data.message);
        }
        else {
            setErrorMessage("Error in getting list");
        }
        console.log("Error in getting users list");
        setShowError(true);
    }
}

const getSubDivisionList = async () => {
    try {
        let url = config["baseurl"] + "/api/subdivision/list?count=" + 10000 + "&offset=" + 0;
        axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
        const { data } = await axios.get(url);

        setSubDivisions(data.list.docs);
    }
    catch (e) {
        console.log(e.response);
        if (e.response) {
            setErrorMessage(e.response.data.message);
        }
        else {
            setErrorMessage("Error in getting list");
        }
        console.log("Error in getting users list");
        setShowError(true);
    }
}

async function getSectionList() {
    console.log("getSectionList called");
    try {
        let url = config["baseurl"] + "/api/section/list?count=" + 10000 + "&offset=" + 0 + "&search=" + "";
        axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
        const { data } = await axios.get(url);

        setSections(data.list.docs);
    }
    catch (e) {
        console.log(e.response);
        if (e.response) {
            setErrorMessage(e.response.data.message);
        }
        else {
            setErrorMessage("Error in getting list");
        }
        console.log("Error in getting users list");
        setShowError(true);
    }
}

async function getActivityList() {
    try {
        let url = config["baseurl"] + "/api/activity/list?count=" + 10000 + "&offset=" + 0 + "&search=" + "";
        axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
        const { data } = await axios.get(url);
        setActivities(data.list.docs);
    }
    catch (e) {
        console.log(e.response);
        if (e.response) {
            setErrorMessage(e.response.data.message);
        }
        else {
            setErrorMessage("Error in getting list");
        }
        console.log("Error in getting users list");
        setShowError(true);
    }
}
  const handleDelete = (index) => {
    let newNums = [...filters];
    newNums.splice(index, 1);
    set_filters(newNums);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const [anchorElSection, setAnchorElSection] = React.useState(null);
  const openSection = Boolean(anchorElSection);

  const [anchorElDataCollector, setAnchorElDataCollector] = React.useState(null);
  const openDataCollector = Boolean(anchorElDataCollector);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorElSection(null);
    setAnchorElDataCollector(null);
  };

  const addFilter = (key) => {
    setAnchorEl(null);

    let fils = [...filters, key];
    set_filters(fils);
  }

  const handleSectionClick = (event) => {
    setAnchorElSection(event.currentTarget);    
  }

  const handleDataCollectorClick = (event) => {
    setAnchorElDataCollector(event.currentTarget);    
  }

  const getTypeString = (type) => {
    switch (type) {
      case "po":
        return "Purchase Order";
        break;
      case "warehouse":
        return "Warehouse 2 Warehouse";
        break;
      case "local_purchase":
        return "Local Purchase";
        break;
      case "return_indent":
        return "Return Indent";
        break;
    }

    return "";
  };

  const handleGetNew = () => {
    getSkippedMeterData(rowsPerPage);
  }

  const dateChanged = (event, type) => {
    console.log(event.target.value);

    if (type == "start")
      setStartDate(event.target.value);
    if (type == "end")
      setEndDate(event.target.value);
  }

  async function exportdata() {
    try {
      setShowBackDrop(true);

      let skipped = 0;
      let validated = 0;
      let notvalidated = 0;
      for (let i = 0; i < filters.length; ++i) {
        if (filters[i] == "skipped")
          skipped = 1;
        else if (filters[i] == "validated")
          validated = 1;
        else if (filters[i] == "notvalidated")
          notvalidated = 1;
      }

      let url = config["baseurl"] + "/api/meterreplacementdata/export?count=" + 1000000000 + "&offset=" + 0 + "&skipped=" + skipped + "&validated=" + validated + "&notvalidated=" + notvalidated + "&startdate=" + startDate + "&enddate=" + endDate + "&section=" +(currentSection?currentSection._id:"")+"&datacollector=" +(currentDataCollector?currentDataCollector._id:"");

      // let url = config["baseurl"] + "/api/storedmaterial/export?count=" + 10000 + "&warehouse=" + props.warehouse._id + "&offset=" + offset + "&search=" + "";
      axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
      const { data } = await axios.get(url);
      console.log(data);

      window.open(data["path"], '_blank', 'noopener,noreferrer');

      setShowBackDrop(false);
    }
    catch (e) {
      setShowBackDrop(false);
      if (e.response) {
        setErrorMessage(e.response.data.message);
      }
      else {
        setErrorMessage("Error in getting list");
      }
      setShowError(true);
    }
  }

  async function selectSection(row) {
    setCurrentSection(row);
    setAnchorElSection(null);
  }

  async function selectDataCollector(row) {
    setCurrentDataCollector(row);
    setAnchorElDataCollector(null);
  }

  return (
    <div className={clsx(classes.root)}>
      {
        <div className={classes.paper}>
          <EnhancedTableToolbar title={"Meter Data Reports"} />

          <Paper className={classes.grid}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      id="demo-positioned-button"
                      aria-controls={open ? 'demo-positioned-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      style={{ background: "#314293", color: "#FFFFFF" }}
                    > Add Filters </Button>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem onClick={() => addFilter("validated")}>Validated</MenuItem>
                      <MenuItem onClick={() => addFilter("notvalidated")}>Not Validated</MenuItem>
                      <MenuItem onClick={() => addFilter("skipped")}>Skipped</MenuItem>
                    </Menu>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: 10 }}>
                      <div>
                        {filters.map((name, index) => {
                          return (<Chip style={{ marginTop: 5, marginRight: 5 }} key={"chip" + index} label={name} clickable variant="outlined" onDelete={() => handleDelete(index)} />);
                        })}
                      </div>
                    </div>
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="start-date"
                      label="Start Date"
                      type="date"
                      defaultValue={currentDate}
                      onChange={(event) => dateChanged(event, "start")}
                      sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="end-date"
                      label="End Date"
                      type="date"
                      defaultValue={currentDate}
                      onChange={(event) => dateChanged(event, "end")}
                      sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          {/* <Paper className={classes.grid}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      id="section-menu"
                      aria-controls={openSection ? 'section-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openSection ? 'true' : undefined}
                      onClick={handleSectionClick}
                      style={{ background: "#314293", color: "#FFFFFF" }}
                    > Section: {currentSection ? currentSection.name : "All"} </Button>
                    <Menu
                      id="section-menu"
                      aria-labelledby="section-button"
                      anchorEl={anchorElSection}
                      open={openSection}
                      onClose={handleMenuClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      {sections.map((row, index) => {
                        return <MenuItem key={""+index} onClick={() => selectSection(row)}>{row.name}</MenuItem>
                      })}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      id="DataCollector-button"
                      aria-controls={openDataCollector ? 'DataCollector-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openDataCollector ? 'true' : undefined}
                      onClick={handleDataCollectorClick}
                      style={{ background: "#314293", color: "#FFFFFF" }}
                    > Data Collector : {currentDataCollector ? currentDataCollector.name : "All"}</Button>
                    <Menu
                      id="DataCollector-menu"
                      aria-labelledby="DataCollector-button"
                      anchorEl={anchorElDataCollector}
                      open={openDataCollector}
                      onClose={handleMenuClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      {dataCollectors.map((row, index) => {
                        return <MenuItem key={""+index} onClick={() => selectDataCollector(row)}>{row.name}</MenuItem>
                      })}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper> */}
          <Paper className={classes.grid}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid item className={classes.totalAttendes}>
                  <h1 className={classes.h1}>{totalCount}</h1>
                  <span>{"Meter Data"}</span>
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div>
                  <Button onClick={() => handleGetNew()} style={{ background: "#314293", color: "#FFFFFF" }} variant="contained" className={classes.button}>{"Get Report"}</Button>
                  {/* <Button onClick={() => exportdata()} style={{ marginLeft:10, background: "#314293", color: "#FFFFFF" }} variant="contained" className={classes.button}>{"Download Report"}</Button> */}
                </div>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.grid}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // const deleted = (row.data.transaction.deleted === 1);
                    return (
                      <TableRow hover tabIndex={-1} key={row.slno}>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} component="th" id={labelId} scope="row" padding="none">{row.slno}</TableCell>

                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{divisions.length>0 ? getDivisionById(row.data.data.division):row.data.data.division}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{subDivisions.length>0 ? getSubDivisionById(row.data.data.subdivision):row.data.data.subdivision}</TableCell> 
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{sections.length>0 ? getSectionById(row.data.data.section):row.data.data.section}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{activities.length>0 ? getActivityById(row.data.data.activity):row.data.data.activity}</TableCell>

                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.billNumber}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.accountID}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.billimagepath}</TableCell>

                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.oldMeterNumber}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.finalReading}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.makeOldMeter}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.capacityOldMeter}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.oldmeterimagepath}</TableCell>
                        
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.newMeterNumber}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.initialReading}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.makeNewMeter}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.capacityNewMeter}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.newmeterimagepath}</TableCell>

                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.user ? row.data.user.name : ""}</TableCell>
                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.user ? row.data.user.phone : ""}</TableCell>

                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.latlong}</TableCell>

                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.datavalidateuser ? row.data.datavalidateuser.name : ""}</TableCell>

                        <TableCell align={dir === 'rtl' ? 'right' : 'left'} >{row.data.data.createdDate_conv.toLocaleString()}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={pageLimits}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      }
      <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Backdrop className={classes.backdrop} open={showBackDrop} onClick={handleCloseBackDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >
  );
}
