import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import EnhancedTableToolbar from './enhancedToolbar';
import axios from 'axios';
import config from "../config.json";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import lstrings from '../lstrings.js';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import 'date-fns';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { v4 as uuidv4 } from 'uuid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const Joi = require('joi');

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddMeterReplacementTeam(props) {

    const dir = document.getElementsByTagName('html')[0].getAttribute('dir');

    const useStyles = makeStyles((theme) => ({
        root: {
            width: 'calc(100%)',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            paddingLeft: 20,
            paddingRight: 20,
        },
        papernew: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            outline: 'none',
            padding: '10px 20px',
            width: '100%',
            borderRadius: '5px',
            overflow: 'auto',
            depth: 1,
            marginTop: '10px',
            marginBottom: '10px',
        },
        grid: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            marginBottom: '10px',
        },
        inputFields: {
            marginTop: 10,
        },
        submit: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '15px',
            margin: '5px',
        },
        formControl: {
            marginTop: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const [name, set_name] = React.useState('');
    const [name_error, set_name_error] = React.useState(null);

    const [phone, set_phone] = React.useState('');
    const [phone_error, set_phone_error] = React.useState(null);

    const [team, set_team] = React.useState('');
    const [team_error, set_team_error] = React.useState(null);

    const [contactingServer, setContactingServer] = React.useState(false);

    const [showBackdrop, setShowBackdrop] = React.useState(false);

    const [divisions, setDivisions] = React.useState([]);
    const [subDivisions, setSubDivisions] = React.useState([]);
    const [sections, setSections] = React.useState([]);
    const [activities, setActivities] = React.useState([]);
    const [serviceVendors, setServiceVendors] = React.useState([]);
    const [currentDivision, setCurrentDivision] = React.useState(-1);
    const [currentSubDivision, setCurrentSubDivision] = React.useState(-1);
    const [currentSection, setCurrentSection] = React.useState(-1);
    const [currentActivity, setCurrentActivity] = React.useState(-1);
    const [currentServiceVendor, setCurrentServiceVendor] = React.useState(-1);

    const getDivisionList = async () => {
        try {
            setShowBackdrop(true);
            let url = config["baseurl"] + "/api/division/list?count=" + 10000 + "&offset=" + 0 + "&search=" + "";
            axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
            const { data } = await axios.get(url);
            // console.log(data);

            setDivisions(data.list.docs);
            setCurrentDivision(0);
            setShowBackdrop(false);
        }
        catch (e) {
            console.log(e.response);
            setShowBackdrop(false);
            if (e.response) {
                setErrorMessage(e.response.data.message);
            }
            else {
                setErrorMessage("Error in getting list");
            }
            console.log("Error in getting users list");
            setShowError(true);
        }
    }

    const getSubDivisionList = async () => {
        try {
            setShowBackdrop(true);
            const divisionIndex = currentDivision === -1 ? 0 : currentDivision;
            let url = config["baseurl"] + "/api/subdivision/list?division=" + divisions[divisionIndex]._id + "&count=" + 10000 + "&offset=" + 0 + "&search=" + "";
            axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
            const { data } = await axios.get(url);
            // console.log(data);

            setSubDivisions(data.list.docs);
            setCurrentSubDivision(0);
            setShowBackdrop(false);
        }
        catch (e) {
            console.log(e.response);
            setShowBackdrop(false);
            if (e.response) {
                setErrorMessage(e.response.data.message);
            }
            else {
                setErrorMessage("Error in getting list");
            }
            console.log("Error in getting users list");
            setShowError(true);
        }
    }

    async function getSectionList(numberOfRows, search = "") {
        console.log("getSectionList called");
        try {
            setShowBackdrop(true);
            const index = currentSubDivision === -1 ? 0 : currentSubDivision;

            let url = config["baseurl"] + "/api/section/list?subdivision=" + subDivisions[index]._id + "&count=" + 10000 + "&offset=" + 0 + "&search=" + search;
            axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
            const { data } = await axios.get(url);
            // console.log(data);

            setSections(data.list.docs);
            setCurrentSection(0);
            setShowBackdrop(false);
        }
        catch (e) {
            console.log(e.response);
            setShowBackdrop(false);
            if (e.response) {
                setErrorMessage(e.response.data.message);
            }
            else {
                setErrorMessage("Error in getting list");
            }
            console.log("Error in getting users list");
            setShowError(true);
        }
    }

    async function getActivityList(numberOfRows, search = "") {
        console.log("getSectionList called");
        try {
            setShowBackdrop(true);
            let url = config["baseurl"] + "/api/activity/list?count=" + 10000 + "&offset=" + 0;
            axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
            const { data } = await axios.get(url);

            setActivities(data.list.docs);
            setCurrentActivity(0);
            setShowBackdrop(false);
        }
        catch (e) {
            console.log(e.response);
            setShowBackdrop(false);
            if (e.response) {
                setErrorMessage(e.response.data.message);
            }
            else {
                setErrorMessage("Error in getting list");
            }
            console.log("Error in getting users list");
            setShowError(true);
        }
    }

    async function getServiceVendorList() {
        try {
            let url = config["baseurl"] + "/api/servicevendor/list?count=" + 1000 + "&offset=" + 0 + "&search=" + "";
            axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
            const { data } = await axios.get(url);
            setServiceVendors(data.list.docs);
        }
        catch (e) {

            if (e.response) {
                setErrorMessage(e.response.data.message);
            }
            else {
                setErrorMessage("Error in getting list");
            }
            setShowError(true);
        }
    }
    useEffect(() => {
        getDivisionList();
        getServiceVendorList();
    }, []);

    useEffect(() => {
        if (divisions.length > 0)
            getSubDivisionList();
    }, [divisions, currentDivision]);

    useEffect(() => {
        if (subDivisions.length > 0)
            getSectionList(100000);
    }, [subDivisions, currentSubDivision]);

    useEffect(() => {
        if (sections.length > 0)
            getActivityList(100000);
    }, [sections, currentSection]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    const handleBreadCrumClick = () => {
        props.history.push("/meterreplacementteam");
    };

    const handleCancel = () => {
        props.history.push("/meterreplacementteam");
    };

    const handleChange = (event) => {
        setCurrentDivision(event.target.value);
    };

    const handleSubDivisionChange = (event) => {
        setCurrentSubDivision(event.target.value);
    };

    const handleSectionChange = (event) => {
        setCurrentSection(event.target.value);
    }

    const handleActivityChange = (event) => {
        setCurrentActivity(event.target.value);
    }

    const handleServiceVendorChange = (event) => {
        setCurrentServiceVendor(event.target.value);
    }

    const validateData = () => {
        const schema = Joi.object({
            name: Joi.string().min(2).max(400).required(),
            phone: Joi.string().min(2).max(500).required(),
            team: Joi.string().min(0).max(1024).required(),
        });
        const { error } = schema.validate({
            name: name.trim(),
            phone: phone.trim(),
            team: team.trim(),
        }, { abortEarly: false });
        const allerrors = {};
        if (error) {
            for (let item of error.details)
                allerrors[item.path[0]] = item.message;
        }

        return allerrors;
    }

    const handleSave = async (e) => {
        e.preventDefault();

        set_name_error(null);
        set_phone_error(null);
        set_team_error(null);
        // set_division_error(null);
        // set_subdivision_error(null);
        // set_section_error(null);

        const errors = validateData();

        let errorOccured = false;
        if (errors["name"]) {
            set_name_error(errors["name"]);
            errorOccured = true;
        }
        if (errors["phone"]) {
            set_phone_error(errors["phone"]);
            errorOccured = true;
        }
        if (errors["team"]) {
            set_team_error(errors["team"]);
            errorOccured = true;
        }
        if (errors["division"]) {
            // set_division_error(errors["division"]);
            errorOccured = true;
        }
        if (errors["subdivision"]) {
            // set_subdivision_error(errors["subdivision"]);
            errorOccured = true;
        }
        if (errors["section"]) {
            // set_section_error(errors["section"]);
            errorOccured = true;
        }

        if(currentServiceVendor == -1)
            return;

        if (errorOccured)
            return;

        try {
            setContactingServer(true);
            let url = config["baseurl"] + "/api/datacollectuser/createadmin";

            let postObj = {};
            postObj["name"] = name.trim();
            postObj["phone"] = phone.trim();
            postObj["team"] = team.trim();
            postObj["division"] = divisions[currentDivision]._id;
            postObj["subdivision"] = subDivisions[currentSubDivision]._id;
            postObj["section"] = sections[currentSection]._id;
            postObj["activity"] = activities[currentActivity]._id;
            postObj["servicevendor"] = serviceVendors[currentServiceVendor]._id;

            axios.defaults.headers.common['authToken'] = window.localStorage.getItem("authToken");
            const response = await axios.post(url, postObj);
            console.log("successfully Saved");

            setContactingServer(false);
            props.history.push("/meterreplacementteam");
        }
        catch (e) {
            console.log("5");
            if (e.response) {
                console.log("Error in creating");
                setErrorMessage(e.response.data["message"]);
            }
            else {
                console.log("Error in creating");
                setErrorMessage("Error in creating: ", e.message);
            }
            setShowError(true);
            setContactingServer(false);
        }
    };

    const handleCloseBackDrop = () => {

    };

    return (
        <div className={clsx(classes.root)}>
            {props.refreshUI &&

                <div className={classes.paper}>

                    <EnhancedTableToolbar title={"Add Meter Replacement Team"} />

                    {/* <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" onClick={handleBreadCrumClick}>
                            {lstrings.Customers}
                        </Link>
                        <Typography color="textPrimary">{Add Meter Replacement Team}</Typography>
                    </Breadcrumbs> */}

                    {/* <Paper className={classes.grid}> */}
                    <form className={classes.papernew} autoComplete="off" noValidate>
                        {/* name */}
                        <TextField className={classes.inputFields} id="formControl_name" defaultValue={name}
                            label="Admin Name *" variant="outlined"
                            onChange={(event) => { set_name(event.target.value); set_name_error(null); }} />
                        {name_error && <Alert className={classes.alert} severity="error"> {name_error} </Alert>}

                        <TextField className={classes.inputFields} id="formControl_phone" defaultValue={phone}
                            label="Phone *" variant="outlined"
                            onChange={(event) => { set_phone(event.target.value); set_phone_error(null); }} />
                        {phone_error && <Alert className={classes.alert} severity="error"> {phone_error} </Alert>}

                        <TextField className={classes.inputFields} id="formControl_team" defaultValue={team}
                            label="Team *" variant="outlined"
                            onChange={(event) => { set_team(event.target.value); set_team_error(null); }} />
                        {team_error && <Alert className={classes.alert} severity="error"> {team_error} </Alert>}

                        <br></br>

                        <Select
                                labelId="demo-simple-select-label-2"
                                id="demo-simple-select-2"
                                variant="outlined"
                                value={currentServiceVendor === -1 ? "" : currentServiceVendor}
                                onChange={handleServiceVendorChange}
                            >
                                {serviceVendors.map((row, index) => {
                                    return (
                                        <MenuItem key={"" + index} value={index}>{row.name}</MenuItem>
                                    );
                                })}
                            </Select>
                            <br></br>
                        <Paper className={classes.grid} style={{ textAlign: 'left' }}>
                            <span>Division:   </span>
                            <Select
                                labelId="demo-simple-select-label-1"
                                id="demo-simple-select-1"
                                value={currentDivision === -1 ? "" : currentDivision}
                                onChange={handleChange}
                            >
                                {divisions.map((row, index) => {
                                    return (
                                        <MenuItem key={"" + index} value={index}>{row.name}</MenuItem>
                                    );
                                })}
                            </Select>
                            <span>       SubDivision:   </span>
                            <Select
                                labelId="demo-simple-select-label-2"
                                id="demo-simple-select-2"
                                value={currentSubDivision === -1 ? "" : currentSubDivision}
                                onChange={handleSubDivisionChange}
                            >
                                {subDivisions.map((row, index) => {
                                    return (
                                        <MenuItem key={"" + index} value={index}>{row.name}</MenuItem>
                                    );
                                })}
                            </Select>
                            <span>       Section:   </span>
                            <Select
                                labelId="demo-simple-select-label-3"
                                id="demo-simple-select-3"
                                value={currentSection === -1 ? "" : currentSection}
                                onChange={handleSectionChange}
                            >
                                {sections.map((row, index) => {
                                    return (
                                        <MenuItem key={"" + index} value={index}>{row.name}</MenuItem>
                                    );
                                })}
                            </Select>
                            <span>       Activity:   </span>
                            <Select
                                labelId="demo-simple-select-label-4"
                                id="demo-simple-select-4"
                                value={currentActivity === -1 ? "" : currentActivity}
                                onChange={handleActivityChange}
                            >
                                {activities.map((row, index) => {
                                    return (
                                        <MenuItem key={"" + index} value={index}>{row.name}</MenuItem>
                                    );
                                })}
                            </Select>
                        </Paper>
                        <div className={classes.submit}>
                            <Button variant="contained" color="primary" onClick={handleCancel} disabled={contactingServer}>Cancel</Button>
                            <Button style={{ marginLeft: 10 }} variant="contained" color="primary" onClick={handleSave} disabled={contactingServer}>Save</Button>
                        </div>

                    </form>
                    {/* </Paper> */}
                </div>
            }
            <Snackbar open={showError} autoHideDuration={60000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={showBackdrop} onClick={handleCloseBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    );
}
