import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import exhibitorsLogo from "../assets/svg/ss/exhibition.svg";
import notFoundImage from "../assets/svg/ss/page-not-found.svg";
import profileLogo from "../assets/svg/ss/profile.svg";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import EditImage from "@material-ui/icons/Edit";
import EnhancedTableToolbar from "./enhancedToolbar";
import axios from "axios";
import config from "../config.json";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Image, { Shimmer } from "react-shimmer";
import { useHistory } from "react-router-dom";
import lstrings from "../lstrings";
import { SaveRounded, SkipNextOutlined } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomInfoAlert from "./CustomInfoAlert";
import DateFnsUtils from "@date-io/date-fns";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function createData(slno, data) {
    return { slno, data };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const dir = document.getElementsByTagName("html")[0].getAttribute("dir");
    const setDir = dir === "rtl" ? true : false;

    const headCells = [
        { id: "slno", numeric: true, disablePadding: true, label: "SL" },
        { id: "name", numeric: false, disablePadding: false, label: "User Name" },
        { id: "email", numeric: false, disablePadding: false, label: "Coordinates" },
        { id: "roles", numeric: false, disablePadding: false, label: "Roles" },
        { id: "action", numeric: false, disablePadding: false, label: "Actions" },
    ];
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={!setDir ? "left" : "right"} padding={headCell.disablePadding ? "none" : "default"} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function MeterDataValidate(props) {
    const dir = document.getElementsByTagName("html")[0].getAttribute("dir");

    const useStyles = makeStyles((theme) => ({
        root: {
            width: "calc(100%)",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
            paddingLeft: 20,
            paddingRight: 20,
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        grid: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
            marginBottom: "10px",
        },
        grid2: {
            padding: theme.spacing(2),
            textAlign: "left",
            color: theme.palette.text.secondary,
            marginBottom: "10px",
        },
        total: {
            textAlign: "left",
        },
        totalDetails: {
            marginRight: "20px",
        },
        totalAttendes: {
            display: "flex",
            alignItems: "baseline",
            width: "30%",
            // marginRight: '80px'
            // borderRight: "1px solid #CACACA",
            "@media (max-width: 600px)": {
                width: "300px",
            },
        },
        progress: {
            width: "70%",
            "@media (max-width: 600px)": {
                width: "600px",
            },
        },
        h1: {
            margin: "0px",
            paddingRight: "10px",
            paddingLeft: "10px",
        },
        vl: {
            border: "1px solid #CACACA",
            height: " 40px",
        },
        search: {
            position: "relative",
        },
        searchIcon: {
            height: "100%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
        },
        inputInput: {
            // width: '90%',
            paddingLeft: "30px",
            paddingRight: "30px",
            borderBottom: "1px solid #CACACA",
        },
        inputRoot: {
            width: "100%",
        },
        button: {
            background: "white",
            textTransform: "capitalize",
        },
        exhibitor_image: {
            marginRight: "10px",
        },
        flex: {
            display: "flex",
            alignItems: "center",
        },
        dot: {
            height: "10px",
            width: "10px",
            backgroundColor: "#bbb",
            borderRadius: "50%",
            display: "inline-block",
            marginRight: "7px",
            marginLeft: "7px",
        },
        dotActive: {
            height: "10px",
            width: "10px",
            backgroundColor: "#4287F5",
            borderRadius: "50%",
            display: "inline-block",
            marginRight: "7px",
            marginLeft: "7px",
        },
        addButton: {
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-end",
            width: "70%",
            // marginRight: '80px'
            // borderRight: "1px solid #CACACA",
            "@media (max-width: 600px)": {
                width: "300px",
            },
        },
    }));

    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(true);
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [rows, setRows] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalVisited, setTotalVisited] = React.useState(0);

    const [oldMeterImage, setOldMeterImage] = React.useState(null);
    const [newMeterImage, setNewMeterImage] = React.useState(null);
    const [billImage, setBillImage] = React.useState(null);

    const [oldMeterNumber, set_oldMeterNumber] = React.useState("");
    const [oldMeterNumber_error, set_oldMeterNumber_error] = React.useState(null);

    const [finalReading, set_finalReading] = React.useState("");
    const [finalReading_error, set_finalReading_error] = React.useState(null);

    const [newMeterNumber, set_newMeterNumber] = React.useState("");
    const [newMeterNumber_error, set_newMeterNumber_error] = React.useState(null);

    const [initialReading, set_initialReading] = React.useState("0");
    const [initialReading_error, set_initialReading_error] = React.useState(null);

    const [billNumber, set_billNumber] = React.useState("");
    const [billNumber_error, set_billNumber_error] = React.useState(null);

    const [accountID, set_accountID] = React.useState("");
    const [accountID_error, set_accountID_error] = React.useState(null);

    const [make_old_meter, set_make_old_meter] = React.useState("");
    const [make_new_meter, set_make_new_meter] = React.useState("L&T");

    const [showInfo, setShowInfo] = React.useState(false);

    const [showBackdrop, setShowBackdrop] = React.useState(false);

    const [all_data, set_all_data] = React.useState(null);

    const history = useHistory();

    const pageLimits = [10, 25, 50];
    let offset = 0;

    const [_data, set_data] = React.useState(null);
    const [_user, set_user] = React.useState(null);
    const [date, set_date] = React.useState(null);

    const [capacityOldMeter, setCapacityOldMeter] = React.useState("");
    const [capacityNewMeter, setCapacityNewMeter] = React.useState("5-30A");

    async function getImageData(url, type) {
        if (url.endsWith("-b.jpg")) {
            if (type == "bill") {
                setBillImage(url);
            } else if (type == "old_meter") {
                setOldMeterImage(url);
            } else if (type == "new_meter") {
                setNewMeterImage(url);
            }
        } else {
            try {
                const { data } = await axios.get(url);
                // console.log(data);
                if (type == "bill") {
                    setBillImage(data);
                } else if (type == "old_meter") {
                    setOldMeterImage(data);
                } else if (type == "new_meter") {
                    setNewMeterImage(data);
                }
            } catch (e) {}
        }
    }

    async function getUnlocked() {
        try {
            let url = config["baseurl"] + "/api/meterreplacementdata/getskippedwithid?id=" + props.skipped_meter_data_id;
            axios.defaults.headers.common["authToken"] = window.localStorage.getItem("authToken");
            const { data } = await axios.get(url);
            console.log(data);

            if (data["data"] == null) {
                setErrorMessage("No meter data available for validation");
                setShowError(true);
                return;
            }

            set_all_data(data);

            if (data["data"].docs.length > 0) {
                let meterData = data["data"].docs[0];

                console.log(meterData);

                const dateFns = new DateFnsUtils();
                set_date(dateFns.date(meterData.createdDate));

                set_data(meterData);
                console.log(meterData);
                set_user(data["datacollectuser"]);

                getImageData(meterData["billimagepath"], "bill");
                getImageData(meterData["oldmeterimagepath"], "old_meter");
                getImageData(meterData["newmeterimagepath"], "new_meter");

                set_billNumber(meterData["billNumber"]);
                set_oldMeterNumber(meterData["oldMeterNumber"]);
                set_newMeterNumber(meterData["newMeterNumber"]);

                set_make_old_meter(meterData["makeOldMeter"]);
                set_make_new_meter(meterData["makeNewMeter"]);

                set_initialReading(meterData["initialReading"]);
                set_finalReading(meterData["finalReading"]);
                set_accountID(meterData["accountID"]);

                setCapacityOldMeter(meterData["capacityOldMeter"]);
                setCapacityNewMeter(meterData["capacityNewMeter"]);
            } else {
                setShowInfo(true);
            }
            console.log(_data);
        } catch (e) {
            console.log("Error in getting users list");
            setErrorMessage("Error in getting users list");
            setShowError(true);
        }
    }

    useEffect(() => {
        console.log("meter-data-skipped-details: ", props.skipped_meter_data_id);
        getUnlocked();
    }, []);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShowError(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: "#1a90ff",
        },
    }))(LinearProgress);

    const getStringForArray = (data) => {
        let val = "";
        for (let i = 0; i < data.length; ++i) {
            if (i > 0) val += ", ";
            val += data[i];
        }
        return val;
    };

    async function handleCancel() {
        props.history.push("/meter-data-audited");
    }

    async function handleSave() {
        let nerrors = 0;

        if (accountID.length == 0) {
            set_accountID_error("Should be 10 digit long only");
            nerrors += 1;
        }

        if (oldMeterNumber.length == 0) {
            set_oldMeterNumber_error("Mandatory *");
            nerrors += 1;
        }
        if (newMeterNumber.length == 0) {
            set_newMeterNumber_error("Mandatory *");
            nerrors += 1;
        }
        if (billNumber.length == 0) {
            set_billNumber_error("Mandatory *");
            nerrors += 1;
        }
        if (initialReading.length == 0) {
            set_initialReading_error("Mandatory *");
            nerrors += 1;
        }
        if (finalReading.length == 0) {
            set_finalReading_error("Mandatory *");
            nerrors += 1;
        }
        if (finalReading.length > 5) {
            set_finalReading_error("Cannot be bigger than 5 digits");
            nerrors += 1;
        }
        if (nerrors > 0) {
            setErrorMessage("Enter all mandatory fields to save");
            setShowError(true);
            return;
        }

        if (checkNewMeterNumberIsValid(newMeterNumber.trim()) == false) {
            setErrorMessage("Fill all details properly");
            setShowError(true);
            return;
        }

        setShowBackdrop(true);
        try {
            let url = config["baseurl"] + "/api/meterreplacementdata/setlocked";

            let postObj = {};
            postObj["_id"] = _data._id;

            axios.defaults.headers.common["authToken"] = window.localStorage.getItem("authToken");

            const updated = await axios.patch(url, postObj);

            console.log("successfully Locked");

            try {
                url = config["baseurl"] + "/api/meterreplacementdata/setverifieddata";

                postObj = {};
                postObj["_id"] = _data._id;
                postObj["billNumber"] = billNumber.trim().toUpperCase();
                postObj["oldMeterNumber"] = oldMeterNumber.trim().toUpperCase();
                postObj["newMeterNumber"] = newMeterNumber.trim().toUpperCase();

                postObj["makeOldMeter"] = make_old_meter.trim().toUpperCase();
                postObj["makeNewMeter"] = make_new_meter.trim().toUpperCase();

                postObj["initialReading"] = initialReading.trim().toUpperCase();
                postObj["finalReading"] = finalReading.trim().toUpperCase();
                postObj["accountID"] = accountID.trim().toUpperCase();

                postObj["capacityOldMeter"] = capacityOldMeter.trim().toUpperCase();
                postObj["capacityNewMeter"] = capacityNewMeter.trim().toUpperCase();

                axios.defaults.headers.common["authToken"] = window.localStorage.getItem("authToken");

                await axios.patch(url, postObj);

                console.log("successfully Validated");
                setShowBackdrop(false);

                props.history.push("/meter-data-audited");
            } catch (e) {
                console.log(e);
                setShowBackdrop(false);
                if (e.response) {
                    setErrorMessage(e.response.data.message);
                } else {
                    setErrorMessage("Error in getting activities for project");
                }
                console.log("Error in getting activities for project");
                setShowError(true);
            }
        } catch (e) {
            console.log(e);
            setShowBackdrop(false);
            if (e.response) {
                setErrorMessage(e.response.data.message);
            } else {
                setErrorMessage("Error in getting activities for project");
            }
            console.log("Error in getting activities for project");
            setShowError(true);
        }
    }

    const handleCloseBackDrop = () => {};

    const closeInfo = () => {
        setShowInfo(false);
        props.history.push("/meter-data-audited");
    };

    const SetNewMeterNumberNow = (str) => {
        set_newMeterNumber(str);

        checkNewMeterNumberIsValid(str);
    };

    const checkNewMeterNumberIsValid = (str) => {
        if (str.match("^([A-z][0-9]+)$")) {
            if (str.length == 8) {
                set_newMeterNumber_error(null);
                return true;
            } else set_newMeterNumber_error("Length should be 8 exactly");
        } else {
            set_newMeterNumber_error("Proper format is required");
        }

        return false;
    };

    return (
        <div className={clsx(classes.root)}>
            {props.refreshUI && (
                <div className={classes.paper}>
                    <EnhancedTableToolbar title={"Audited Meter Data"} />
                    <Paper className={classes.grid2}>
                        <span>
                            <b>Data Collected by</b>
                            <br></br>
                            {_user ? _user.name : ""}
                            <br></br>
                            {_user ? _user.phone : ""}
                            <br></br>
                            {date ? date.toLocaleString() : ""}
                            <br></br>
                            {"LatLong: " + (_data ? _data.latlong : "")}
                            <br></br>
                            {"Division: " + (all_data ? all_data.division.name : "")}
                            <br></br>
                            {"SubDivision: " + (all_data ? all_data.subdivision.name : "")}
                            <br></br>
                            {"Section: " + (all_data ? all_data.section.name : "")}
                            <br></br>
                            {"Activity: " + (all_data ? all_data.activity.name : "")}
                        </span>
                    </Paper>
                    <Paper className={classes.grid2}>
                        <span>
                            <b>Skip Remarks</b>
                            <br></br>
                            {_data && _data.skippedRemarks ? _data.skippedRemarks : ""}
                        </span>
                    </Paper>
                    <Paper className={classes.grid}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <img src={billImage} width="400" alt="" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    className={classes.inputFields}
                                    id="formControl_billNumber"
                                    value={billNumber}
                                    label="RR Number *"
                                    variant="outlined"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        set_billNumber(event.target.value);
                                        set_billNumber_error(null);
                                    }}
                                />
                                {billNumber_error && (
                                    <Alert className={classes.alert} severity="error">
                                        {" "}
                                        {billNumber_error}{" "}
                                    </Alert>
                                )}
                                <br></br>
                                <br></br>
                                <TextField
                                    size="small"
                                    className={classes.inputFields}
                                    id="formControl_accountID"
                                    value={accountID}
                                    label="Acccount ID *"
                                    variant="outlined"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        set_accountID(event.target.value);
                                        set_accountID_error(null);
                                    }}
                                />
                                {accountID_error && (
                                    <Alert className={classes.alert} severity="error">
                                        {" "}
                                        {accountID_error}{" "}
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.grid}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <img src={oldMeterImage} width="400" alt="" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    className={classes.inputFields}
                                    id="formControl_oldMeterNumber"
                                    value={oldMeterNumber}
                                    label="Old Meter Number *"
                                    variant="outlined"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        set_oldMeterNumber(event.target.value);
                                        set_oldMeterNumber_error(null);
                                    }}
                                />
                                {oldMeterNumber_error && (
                                    <Alert className={classes.alert} severity="error">
                                        {" "}
                                        {oldMeterNumber_error}{" "}
                                    </Alert>
                                )}
                                <br></br>
                                <br></br>
                                <TextField
                                    size="small"
                                    className={classes.inputFields}
                                    id="formControl_finalReading"
                                    value={finalReading}
                                    label="Final Reading *"
                                    variant="outlined"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        set_finalReading(event.target.value);
                                        set_finalReading_error(null);
                                    }}
                                />
                                {finalReading_error && (
                                    <Alert className={classes.alert} severity="error">
                                        {" "}
                                        {finalReading_error}{" "}
                                    </Alert>
                                )}
                                <br></br>
                                <br></br>
                                <TextField
                                    size="small"
                                    className={classes.inputFields}
                                    id="formControl_make_old_meter"
                                    value={make_old_meter}
                                    label="Manufacturer"
                                    variant="outlined"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        if (event.target.value.match(/^[A-Za-z!@#$%^&* ]+$/) !== null) {
                                            set_make_old_meter(event.target.value);
                                        }
                                    }}
                                />
                                <br></br>
                                <br></br>
                                <TextField
                                    size="small"
                                    className={classes.inputFields}
                                    id="formControl_capactity_old_meter"
                                    value={capacityOldMeter}
                                    label="Capacity Old Meter"
                                    variant="outlined"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        setCapacityOldMeter(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.grid}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <img src={newMeterImage} width="400" alt="" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    className={classes.inputFields}
                                    id="formControl_newMeterNumber"
                                    value={newMeterNumber}
                                    label="New Meter Number *"
                                    variant="outlined"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        SetNewMeterNumberNow(event.target.value);
                                    }}
                                />
                                {newMeterNumber_error && (
                                    <Alert className={classes.alert} severity="error">
                                        {" "}
                                        {newMeterNumber_error}{" "}
                                    </Alert>
                                )}
                                <br></br>
                                <br></br>
                                <TextField
                                    size="small"
                                    className={classes.inputFields}
                                    id="formControl_initialReading"
                                    value={initialReading}
                                    label="Initial Reading *"
                                    variant="outlined"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        set_initialReading(event.target.value);
                                        set_initialReading_error(null);
                                    }}
                                />
                                {initialReading_error && (
                                    <Alert className={classes.alert} severity="error">
                                        {" "}
                                        {initialReading_error}{" "}
                                    </Alert>
                                )}
                                <br></br>
                                <br></br>
                                <TextField
                                    size="small"
                                    className={classes.inputFields}
                                    id="formControl_make_new_meter"
                                    value={make_new_meter}
                                    label="Manufacturer"
                                    variant="outlined"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        set_make_new_meter(event.target.value);
                                    }}
                                />
                                <br></br>
                                <br></br>
                                <TextField
                                    size="small"
                                    className={classes.inputFields}
                                    id="formControl_capactity_new_meter"
                                    value={capacityNewMeter}
                                    label="Capacity New Meter"
                                    variant="outlined"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(event) => {
                                        setCapacityNewMeter(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.grid}>
                        <div>
                            <Button onClick={() => handleCancel()} color="primary" className={classes.button}>
                                <SkipNextOutlined />
                                Cancel
                            </Button>
                            <Button onClick={() => handleSave()} color="primary" className={classes.button}>
                                <SaveRounded />
                                Save
                            </Button>
                        </div>
                    </Paper>
                </div>
            )}

            {showInfo && <CustomInfoAlert close={closeInfo} title="SmartEx" message="Not data available" />}

            <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Backdrop className={classes.backdrop} open={showBackdrop} onClick={handleCloseBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
