import axios from "axios";
import config from "../config.json";

class Utils {
    static createData = (slno, data) => {
        return { slno, data };
    };

    static a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    static formatDate(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return "" + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "  " + strTime;
    }

    static formatDateFromString(dateString) {
        let date = new Date(dateString);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return "" + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "  " + strTime;
    }

    static getDateString(indate) {
        return "" + indate.getFullYear() + "-" + String(indate.getMonth() + 1).padStart(2, "0") + "-" + String(indate.getDate()).padStart(2, "0");
    }

    static getSerialSplits(str) {
        var patt1 = /[0-9]/g;
        var patt2 = /[a-zA-Z]/g;
        var letters = str.match(patt2);
        var digits = str.match(patt1);

        var letterPart = "";
        if (letters) {
            for (let i = 0; i < letters.length; ++i) {
                letterPart += letters[i];
            }
        }

        var numberPart = "";
        for (let i = 0; i < digits.length; ++i) {
            numberPart += digits[i];
        }

        return { letterPart: letterPart, numberPart: numberPart };
    }

    static waitTillTransactionComplete = async (transactionId) => {
        let queueurl = config["baseurl"] + "/api/transactionqueue/list";
        let jobExecuting = false;
        while (true) {
            const queueResponse = await axios.get(queueurl);
            if (queueResponse) {
                jobExecuting = false;
                for (let i = 0; i < queueResponse.data.length; ++i) {
                    if (queueResponse.data[i]._id == transactionId) {
                        jobExecuting = true;
                        break;
                    }
                }
                if (!jobExecuting) {
                    break;
                }
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        }
    };

    static getTransactionResponse = async (transactionId) => {
      try{
        let queueurl = config["baseurl"] + "/api/transactionqueue/detail?transaction="+transactionId;
        const response = await axios.get(queueurl);
        console.log(response.data);
        return response;
      }
      catch(e){
        return {status:400, data:{message:"something went wrong"}};
      }
    };
}

export default Utils;

// exports.createData = createData;
// exports.a11yProps = a11yProps;
// exports.formatDate = formatDate;
// exports.formatDateFromString = formatDateFromString;
// exports.getDateString = getDateString;
// exports.getSerialSplits = getSerialSplits;
// exports.waitTillTransactionComplete = waitTillTransactionComplete;
